<template>
	<div class='container'>
		<div class="title">
      <span class="inner_title">楼栋管理</span>
    </div>
		<div class="top-box">
			<div class="top-two">
				<el-button type="primary" @click="addClick">添加单元楼栋</el-button>
			</div>
		</div>
    <div class="cont_container">
      <template>
        <el-table class="table" 
          v-loading="loading"  
          element-loading-spinner="el-icon-loading"
          :element-loading-text="loadingText"
          :element-loading-background="loadingColor"
          :data="tableData" 
          :header-cell-style="TableTitleBackColor"
          :row-style="tableCss" 
          :highlight-current-row="true" 
          :cell-style="cellStyle">
          <el-table-column  label="序号" type="index" width="150">
          </el-table-column>
          <el-table-column prop="building_number" label="所属楼栋">
          </el-table-column>
          <el-table-column prop="unit_numbers" label="单元号">
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="goToEditPage(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
		<el-pagination style="width: 100%;text-align: center;" background layout="prev, pager, next" :page-count="last_page"
		 :current-page="page" @current-change="pageChange">
		</el-pagination>
	</div>
</template>
<script>
	import tableCss from '../../../style/tableCss.js';
	import {mapState} from "vuex";
  import {getLouDongListFn} from '@/api/louDong.js'
	export default {
		props: [],
		data() {
			return {
				loading: false,
				tableData: [],
				page: 1,
				page_size: 10,
				last_page: 0,
			}
		},
		computed: {
			...mapState(['loadingColor', 'loadingText'])
		},
    mounted() {
      console.log(mapState)
      console.log(this.$store)
      console.log(this.$store.state.loadingText)
      console.log(this.loadingText)
			this.getLouDongList();
		},
		created() {},
		methods: {
      //获取楼栋列表
			getLouDongList() {
				this.loading = true;
        console.log(localStorage.getItem('token1'))
        let params = {
          api_token: localStorage.getItem('token1'),
					page: this.page,
					page_size: this.page_size,
        }
				getLouDongListFn(params).then(res => {
					this.tableData = res.datas.data;
					this.last_page = res.meta.last_page;
					this.loading = false;
				})
			},
			//新增
			addClick() {
				this.$router.push({
					path: '/addLouDong'
				});
			},
			//编辑
			goToEditPage(row) {
				this.$router.push({
					path: '/editLouDong',
					query: {
						id: row.id,
						page: this.page,
						last_page: this.last_page,
					}
				});
			},
			//页数改变
			pageChange(page) {
				this.page = page;
				this.getLouDongList();
			},
			//表头样式
			TableTitleBackColor() {
				return tableCss.tableTitleBackColor();
			},
			//表格样式
			tableCss() {
				return tableCss.tableCss();
			},
			//单元格样式
			cellStyle() {
				return tableCss.cellStyle();
			},
		},
		activated() {

		}
	}
</script>
<style scoped>
	@import url("../../../style/title.css");
</style>
